var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-white text-dark p-2 mb-3"},[_c('TInputCheckbox',{attrs:{"checked":_vm.expected_payment_due_date_empty,"disabled":_vm.loading,"label":"No payment due date"},on:{"change":_vm.changeSwitch}})],1),_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":_vm.store,"resource":_vm.resource,"enterable":"","reloadable":"","deletable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.refresh},scopedSlots:_vm._u([{key:"first_item",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.first_item)?_c('SCardProductItems',{attrs:{"item":item.first_item.product,"resource":"/sale/goods/products","itemsCount":item.items_count,"widthAuto":""}}):_c('TMessageNotFound')],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageOrderStatus',{attrs:{"id":item.status_id}}),_c('SMessageIkomOrderStatus',{attrs:{"id":item.primary_status}})],1)]}},{key:"customer_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.customer)?_c('TLink',{attrs:{"to":_vm.lodash.getReferenceLink('user', item.customer_id),"content":item.customer.name}}):_vm._e()],1)]}},{key:"cost_of_goods",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.cost_of_goods,"currency":item.purchase_cost_currency_id}})],1)]}},{key:"shipment_method_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageShipmentMethod',{attrs:{"id":item.shipment_method_id}})],1)]}},{key:"note",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.note,"noTranslate":""}})],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at,"small":""}})],1)]}},{key:"payment_due_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.payment_due_date,"dateOnly":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id,"placeholder":"Order Id"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"first_item-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter['items.product_id'],"placeholder":"Jancode"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, 'items.product_id', $event)},_vm.filterChange]}})]},proxy:true},{key:"customer_id-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"value":_vm.filter.customer_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "customer_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"shipment_method_id-filter",fn:function(){return [_c('SSelectShipmentMethod',{attrs:{"value":_vm.filter.shipment_method_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "shipment_method_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"status-filter",fn:function(){return [_c('SSelectOrderStatus',{staticClass:"text-truncate-0",attrs:{"store":_vm.orderStatusStore,"value":_vm.filter.status_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "status_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"created_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.filter_between['created_at']},on:{"update:value":[function($event){return _vm.$set(_vm.filter_between, 'created_at', $event)},_vm.filterChange]}})]},proxy:true},{key:"payment_due_date-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.filter_between['payment_due_date']},on:{"update:value":[function($event){return _vm.$set(_vm.filter_between, 'payment_due_date', $event)},_vm.filterChange]}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }